<!--
 * @Description: 硬件商品日志
 * @Author: ChenXueLin
 * @Date: 2022-01-21 10:32:44
 * @LastEditTime: 2022-07-15 15:45:48
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="goodsName">
              <el-input
                v-model="searchForm.goodsName"
                placeholder="商品名称"
                title="商品名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="operationTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="操作时间"
                ref="effectiveDatePicker"
                v-model="searchForm.operationTime"
                title="操作时间"
                :picker-options="pickerOptions('searchForm.operationTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="操作时间（始）"
                end-placeholder="操作时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <!-- <i class="e6-icon-history" title="历史记录"></i> -->
          <el-button
            type="primary"
            @click="handleContrast"
            style="margin-right:10px"
            >对比信息</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :header-cell-class-name="cellClass"
          class="table"
        >
          <el-table-column
            type="selection"
            width="50"
            :selectable="checkSelectable"
          ></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'attrName'">
                <el-button type="text" @click="toDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column> -->
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!--   -->
    <Contrast
      :dialogVisible="dialogVisible"
      :data="contrastData"
      :columnTitle="columnTitle"
      :rowData="rowData"
      @handleClose="handleClose"
    >
    </Contrast>
  </div>
</template>

<script>
// import { printError } from "@/utils/util";
import { getGoodsHis } from "@/api";
import record from "../mixins";
export default {
  name: "hardwareGoodsRecord",
  data() {
    return {
      searchForm: {
        goodsName: "",
        operationTime: [],
        pageIndex: 1,
        pageSize: 20
      },
      queryListAPI: getGoodsHis, //获取列表的请求名
      // getContrastInfo: getContrastInfo,
      headerType: 1 //硬件商品表头
    };
  },
  mixins: [record],
  watch: {
    // 创建开始时间
    "searchForm.operationTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.operationTime = this.searchForm.operationTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  computed: {},
  components: {},
  methods: {}
};
</script>
<style lang="scss" scoped>
/deep/.table {
  .DisableSelection {
    .cell {
      display: none !important;
    }
  }
}
</style>
